import React from "react";
import Lottie from "lottie-react";
import saltaAnimationData from "./salta.json";
import cafayateAnimationData from "./cafayate.json";
import iruyaAnimationData from "./iruya.json";
const CityAnimation = ({ cityName }: any) => {
  let animationData = saltaAnimationData;
  switch (cityName) {
    case "salta":
      animationData = saltaAnimationData;
      break;
    case "cafayate":
      animationData = cafayateAnimationData;
      break;
    case "iruya":
      animationData = iruyaAnimationData;
      break;
    default:
      break;
  }
  return (
    <div
      style={{
        width: "100%",
        height: "0",
        paddingBottom: "24%",
        position: "relative",
      }}
    >
      <Lottie
        loop={false}
        animationData={animationData}
        style={{ position: "absolute", width: "100%", height: "100%" }}
      />
    </div>
  );
};

export default CityAnimation;
