import React, { useEffect, useState } from "react";
import CityAnimation from "../../components/animations/CityAnimation/CityAnimation";
import "./ServicesPage.scss";
import { Header } from "../../components/Header/Header";
import { ContentModal } from "../../components/ContentModal/ContentModal";
import { ContentAnimation } from "../../components/animations/ContentAnimation/ContentAnimation";
const ServicesPage = () => {
  const [contentModalIsOpen, setContentModalIsOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setContentModalIsOpen(true);
      setShowContent(true);
    }, 3500);
  }, []);
  return (
    <div className="services-page-container">
      <Header />
      <div className="page-body">
        {/* <ContentModal open={contentModalIsOpen} /> */}
        {showContent && (
          <div>
            <ContentAnimation section={"services"} />
            <h1>Nuestros servicios</h1>
            <div>
              <h2>Desarrollo de aplicaciones móviles</h2>
              <p>
                Desarrollamos aplicaciones móviles personalizadas para iOS y
                Android, adaptadas a tus necesidades
              </p>
            </div>
            <div>
              <h2>Desarrollo de sitios web</h2>
              <p>
                Ofrecemos soluciones de diseño y desarrollo de sitios web que se
                ajustan a tus objetivos y proporcionan una experiencia de
                usuario óptima.
              </p>
            </div>
            <div>
              <h2>Desarrollo de software personalizado</h2>
              <p>
                Desarrollamos soluciones de software a medida que se ajustan a
                tus necesidades y te ayudan a mejorar tus procesos de negocio.
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="city-wrapper">
        <CityAnimation cityName="iruya" />
      </div>
    </div>
  );
};

export { ServicesPage };
