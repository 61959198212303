import React, { useEffect, useState } from "react";
import "./HomePage.scss";
import CityAnimation from "../../components/animations/CityAnimation/CityAnimation";
import { Header } from "../../components/Header/Header";
import { ContentModal } from "../../components/ContentModal/ContentModal";
import { ContentAnimation } from "../../components/animations/ContentAnimation/ContentAnimation";
const HomePage = () => {
  const [contentModalIsOpen, setContentModalIsOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setContentModalIsOpen(true);
      setShowContent(true);
    }, 3500);
  }, []);
  return (
    <div className="home-page-container">
      <Header />
      <div className="page-body">
        {/* <ContentModal open={contentModalIsOpen} /> */}
        {showContent && (
          <div>
            <ContentAnimation section={"home"} />
            <h1>¡Bienvenidos a Nubelon!</h1>
            <p>
              Desarrollamos soluciones de software innovadoras y personalizadas
              para impulsar tu negocio. Ubicados en Salta, Argentina, estamos
              comprometidos con la excelencia en el desarrollo de software.
            </p>
          </div>
        )}
      </div>
      <div className="city-wrapper">
        <CityAnimation cityName="salta" />
      </div>
    </div>
  );
};

export { HomePage };
