import React from "react";
import "./Header.scss";
import LogoAnimation from "../animations/LogoAnimation/LogoAnimation";
import { NavLink, useLocation } from "react-router-dom";
const Header = () => {
  return (
    <div className="header-container">
      <div className="logo-wrapper md">
        <LogoAnimation width={250} height={67.5} />
      </div>
      <div className="logo-wrapper sm">
        <LogoAnimation width={200} height={54} />
      </div>
      <div className="menu-wrapper">
        <div>
          <NavLink
            className={({ isActive }) => (isActive ? "active-item" : "")}
            to="/"
          >
            <span>Inicio</span>
          </NavLink>

          <NavLink
            className={({ isActive }) => (isActive ? "active-item" : "")}
            to="/services"
          >
            <span>Servicios</span>
          </NavLink>
          <NavLink
            className={({ isActive }) => (isActive ? "active-item" : "")}
            to="/contact"
          >
            <span>Contacto</span>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export { Header };
