import React from "react";
import Lottie from "lottie-react";
import logoAnimationData from "./logo.json";

const LogoAnimation = ({ width = 200, height = 54 }: any) => (
  <Lottie
    loop={false}
    animationData={logoAnimationData}
    //style={{ position: "absolute", width: "100%", height: "100%" }}
    //style={{ width: 150, height: 40.5 }}
    style={{ width: width, height: height }}
  />
);

export default LogoAnimation;
