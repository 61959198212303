import React, { useEffect, useState } from "react";
import "./ContactPage.scss";
import CityAnimation from "../../components/animations/CityAnimation/CityAnimation";
import { Header } from "../../components/Header/Header";
import { ContentModal } from "../../components/ContentModal/ContentModal";
import { ContentAnimation } from "../../components/animations/ContentAnimation/ContentAnimation";
const ContactPage = () => {
  const [contentModalIsOpen, setContentModalIsOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setContentModalIsOpen(true);
      setShowContent(true);
    }, 3500);
  }, []);
  return (
    <div className="contact-page-container">
      <Header />
      <div className="page-body">
        {/* <ContentModal open={contentModalIsOpen} /> */}
        {showContent && (
          <div>
            {/* <ContentAnimation section={"contact"} /> */}
            <h1>Contacto</h1>
            <p>
              Aquí en Nubelon estamos comprometidos a desarrollar relaciones a
              largo plazo con nuestros clientes. Siempre hay alguien en Nubelon
              para ayudarte con una consulta o pregunta de soporte. Si quiere
              ser parte de nuestros clientes o requiere de una capacitación,
              soporte o documentación no dude en ponerse en contacto con
              nosotros.
            </p>
            <p>
              Nuestra oficina: Adolfo Güémez 505, Oficina 0, salta capital.
              <br />
              <a
                href="https://api.whatsapp.com/send?phone=543875847526"
                target="_blank"
              >
                Whatsapp +54 3875847526
              </a>
            </p>
          </div>
        )}
      </div>
      <div className="city-wrapper">
        <CityAnimation cityName="cafayate" />
      </div>
    </div>
  );
};

export { ContactPage };
